import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Button,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { buildErrorMessage } from "../../common/graphql/errors";
import {
  ClientAuthMethod,
  DeleteClientMutation,
  DeleteClientMutationVariables,
  GetClientQuery,
  GetClientQueryVariables,
  UpdateClientMutation,
  UpdateClientMutationVariables,
} from "../../generated";
import { ClientForm } from "./components/ClientForm";
import { DELETE_CLIENT, UPDATE_CLIENT } from "./graphql/mutation";
import { GET_CLIENT } from "./graphql/query";

export const ClientEditPage = () => {
  const params = useParams();
  const { data: client, loading: clientLoading } = useQuery<
    GetClientQuery,
    GetClientQueryVariables
  >(GET_CLIENT, {
    variables: { id: params.id },
  });
  const [updateClient, { data, error, loading }] = useMutation<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >(UPDATE_CLIENT);

  const [deleteClient, { loading: deleteLoading }] = useMutation<
    DeleteClientMutation,
    DeleteClientMutationVariables
  >(DELETE_CLIENT);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.updateClient?.id) {
      enqueueSnackbar("Client updated", { variant: "success" });
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: "error" });
  }

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader
        title="Edit Client"
        action={
          <LoadingButton
            variant="outlined"
            color="error"
            loading={deleteLoading}
            onClick={() => {
              const confirm = window.confirm("Are you sure to delete this");
              if (confirm) {
                deleteClient({ variables: { id: params.id } })
                  .then((response) => {
                    enqueueSnackbar("Deleted", { variant: "success" });
                    navigate("/clients");
                  })
                  .catch((e) => {
                    enqueueSnackbar(buildErrorMessage(e), {
                      variant: "error",
                    });
                  });
              }
            }}
          >
            Delete
          </LoadingButton>
        }
      ></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        {clientLoading && !client?.findClient ? (
          <CircularProgress />
        ) : (
          <>
            <ClientForm
              type="update"
              initialValues={{ ...client?.findClient }}
              loading={loading}
              onSubmit={(values) => {
                updateClient({
                  variables: {
                    updates: {
                      name: values.name,
                      clientId: values.clientId,
                      clientSecret: values.clientSecret,
                      redirectUris: values.redirectUris,
                      responseTypes: values.responseTypes,
                      grantTypes: values.grantTypes,
                      tokenEndpointAuthMethod: values.tokenEndpointAuthMethod,
                      scopes: values.scopes,
                    },
                    id: params.id,
                  },
                });
              }}
            ></ClientForm>
          </>
        )}
      </CardContent>
    </Card>
  );
};
