import { useMutation } from "@apollo/client";
import { Card, CardHeader, Button, CardContent } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { buildErrorMessage } from "../../common/graphql/errors";
import {
  ClientAuthMethod,
  CreateClientMutation,
  CreateClientMutationVariables,
} from "../../generated";
import { ClientForm } from "./components/ClientForm";
import { CREATE_CLIENT } from "./graphql/mutation";

export const ClientCreatePage = () => {
  const [createClient, { data, error, loading }] = useMutation<
    CreateClientMutation,
    CreateClientMutationVariables
  >(CREATE_CLIENT);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.createClient?.id) {
      enqueueSnackbar("Client created", { variant: "success" });
      navigate(`/clients/${data.createClient.id}/edit`);
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: "error" });
  }

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader title="New Client"></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <ClientForm
          type="create"
          initialValues={{
            name: "",
            clientId: "",
            clientSecret: "",
            redirectUris: [],
            responseTypes: [],
            grantTypes: [],
            tokenEndpointAuthMethod: ClientAuthMethod.None,
            scopes: ["email", "profile", "openid", "offline_access"],
          }}
          loading={loading}
          onSubmit={(values) => {
            createClient({ variables: { client: values } });
          }}
        ></ClientForm>
      </CardContent>
    </Card>
  );
};
