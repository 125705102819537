import { useQuery } from "@apollo/client";
import { Card, CardHeader, Button, CardContent } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { GetAllAuditsQuery, GetAllAuditsQueryVariables } from "../../generated";
import { GET_AUDITS } from "./graphql/query";

export const UserAuditPage = () => {
  const params = useParams();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const { data, loading } = useQuery<
    GetAllAuditsQuery,
    GetAllAuditsQueryVariables
  >(GET_AUDITS, {
    variables: {
      where: { user: { id: { _eq: params.id } } },
      limit,
      offset: page * limit,
    },
  });

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardContent sx={{ flex: 1 }}>
        <DataGridPro
          rows={data?.findAllAudits?.nodes || []}
          rowCount={data?.findAllAudits?.totalCount || 0}
          pagination
          paginationMode="server"
          loading={loading}
          page={page}
          pageSize={limit}
          onPageChange={(p) => {
            setPage(p);
          }}
          onPageSizeChange={(p) => {
            setLimit(p);
          }}
          columns={[
            { field: "id", headerName: "ID", flex: 0.2 },
            { field: "action", headerName: "Action", flex: 1 },
            { field: "ip", headerName: "IP", flex: 0.5 },
            { field: "country", headerName: "Country", flex: 0.3 },
            {
              field: "data",
              headerName: "Data",
              flex: 1,
              renderCell: ({ value }) => {
                return JSON.stringify(value);
              },
            },
            { field: "createdAt", headerName: "Created At", flex: 1 },
          ]}
        ></DataGridPro>
      </CardContent>
    </Card>
  );
};
