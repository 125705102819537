import { useMutation } from "@apollo/client";
import { Card, CardHeader, Button, CardContent } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { buildErrorMessage } from "../../common/graphql/errors";
import { CreateUserMutation, CreateUserMutationVariables } from "../../generated";
import { UserForm } from "./components/UserForm";
import { CREATE_USER } from "./graphql/mutation";

export const UserCreatePage = () => {
  const [createUser, { data, error, loading }] = useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CREATE_USER);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.createUser?.id) {
      enqueueSnackbar("User created", { variant: "success" });
      navigate(`/users/${data.createUser.id}/edit`);
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: "error" });
  }

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader title="New User"></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <UserForm
          type="create"
          initialValues={{
            email: "",
            enabled: false,
            password: "",
            username: "",
            roles: [],
          }}
          loading={loading}
          onSubmit={(values) => {
            createUser({ variables: { user: values } });
          }}
        ></UserForm>
      </CardContent>
    </Card>
  );
};
