import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const auth = window.localStorage.getItem("fwen_auth");
    if (auth) {
      const parsed = JSON.parse(auth);
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${parsed["AUTH_STATE"]["accessToken"]}`,
        },
      };
    }
    return {
      headers,
    };
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions.code === "UNAUTHENTICATED") {
        window.localStorage.removeItem("fwen_auth");
        window.location.href = "/login";
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
});
export const client = new ApolloClient({
  cache: new InMemoryCache({ resultCaching: false }),
  link: ApolloLink.from([authMiddleware, errorLink, httpLink]),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
  },
});
