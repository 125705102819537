import { gql } from "@apollo/client";

export const ClientFields = gql`
  fragment ClientFields on Client {
    id
    name
    redirectUris
    scopes
    responseTypes
    grantTypes
    tokenEndpointAuthMethod
    clientSecret
    clientId
    updatedAt
    createdAt
  }
`;
