import { gql } from "@apollo/client";
import { ClientFields } from "./fragment";

export const GET_ALL_CLIENTS = gql`
  ${ClientFields}
  query GetAllClients {
    findAllClients {
      ...ClientFields
    }
  }
`;

export const GET_CLIENT = gql`
  ${ClientFields}
  query GetClient($id: String!) {
    findClient(id: $id) {
      ...ClientFields
    }
  }
`;
