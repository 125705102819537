import { atom } from "recoil";
import { recoilPersist } from "../../common/state-persist";

export const { persistAtom } = recoilPersist({ key: "fwen_auth" });

export interface AuthState {
  accessToken: string;
  expire: number;
}

export const AUTH_STATE = atom<AuthState>({
  key: "AUTH_STATE",
  default: {
    accessToken: "",
    expire: 0,
  },
  effects: [persistAtom],
});
