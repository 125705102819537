import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation UpdateUser($id: String!, $updates: UpdateUserInput!) {
    updateUser(id: $id, updates: $updates) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($user: CreateUserInput!) {
    createUser(user: $user) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
