import { useLazyQuery, useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import { GET_ALL_USERS } from "./graphql/query";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GetAllUsersQuery, GetAllUsersQueryVariables } from "../../generated";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const UserIndexPage = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const { data, loading } = useQuery<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
  >(GET_ALL_USERS, { variables: { limit, offset: page * limit } });

  const navigate = useNavigate();

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader
        title="Users"
        action={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("/users/new");
            }}
          >
            New
          </Button>
        }
      ></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <DataGridPro
          rows={data?.findAllUsers?.nodes || []}
          rowCount={data?.findAllUsers?.totalCount || 0}
          pagination
          paginationMode="server"
          loading={loading}
          page={page}
          pageSize={limit}
          onPageChange={(p) => {
            setPage(p);
          }}
          onPageSizeChange={(p) => {
            setLimit(p);
          }}
          columns={[
            { field: "id", headerName: "ID", flex: 0.2 },
            { field: "email", headerName: "Email", flex: 1 },
            {
              field: "roles",
              headerName: "Roles",
              flex: 0.2,
              renderCell: ({ value }) => {
                return value ? value.join(",") : "";
              },
            },
            {
              field: "kycVerified",
              headerName: "KYC Verified",
              flex: 0.2,
              type: "boolean",
              renderCell: ({ value }) => {
                return !!value ? "Yes" : "No";
              },
            },
            {
              field: "emailVerified",
              headerName: "Email Verified",
              flex: 0.2,
              type: "boolean",
              renderCell: ({ value }) => {
                return !!value ? "Yes" : "No";
              },
            },
            { field: "updatedAt", headerName: "UpdatedAt", flex: 0.3 },
            {
              field: "actions",
              headerName: "Actions",
              flex: 0.3,
              renderCell: (row) => {
                return (
                  <ButtonGroup>
                    <Button
                      onClick={() => {
                        navigate(`/users/${row.row.id}/edit`);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(`/users/${row.row.id}/audits`);
                      }}
                    >
                      Audits
                    </Button>
                  </ButtonGroup>
                );
              },
            },
          ]}
        ></DataGridPro>
      </CardContent>
    </Card>
  );
};
