import { useMutation, useQuery } from "@apollo/client";
import { Card, CardHeader, CardContent, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { buildErrorMessage } from "../../common/graphql/errors";
import {
  GetUserQuery,
  GetUserQueryVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "../../generated";
import { UserForm } from "./components/UserForm";
import { UPDATE_USER } from "./graphql/mutation";
import { GET_USER } from "./graphql/query";

export const UserEditPage = () => {
  const params = useParams();
  const { data: user, loading: userLoading } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GET_USER, {
    variables: { id: params.id },
  });
  const [updateUser, { data, error, loading }] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UPDATE_USER);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.updateUser?.id) {
      enqueueSnackbar("User updated", { variant: "success" });
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: "error" });
  }

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader title="Edit User"></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        {userLoading && !user?.findUser ? (
          <CircularProgress />
        ) : (
          <UserForm
            type="update"
            initialValues={{ ...user?.findUser }}
            loading={loading}
            onSubmit={(values) => {
              updateUser({
                variables: {
                  updates: {
                    username: values.username,
                    enabled: values.enabled,
                    roles: values.roles,
                    kycVerified: values.kycVerified,
                    emailVerified: values.emailVerified,
                    password: values.password,
                  },
                  id: params.id,
                },
              });
            }}
          ></UserForm>
        )}
      </CardContent>
    </Card>
  );
};
