import { gql } from "@apollo/client";

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($id: String!, $updates: UpdateClientInput!) {
    updateClient(id: $id, updates: $updates) {
      id
    }
  }
`;

export const CREATE_CLIENT = gql`
  mutation CreateClient($client: CreateClientInput!) {
    createClient(client: $client) {
      id
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation DeleteClient($id: String!) {
    deleteClient(id: $id) {
      id
    }
  }
`;
