import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import {
  Client,
  ClientAuthMethod,
  GrantType,
  ResponseType,
} from "../../../generated";

export const ClientForm = ({
  initialValues,
  onSubmit,
  loading,
  type,
}: {
  initialValues: Partial<Client & { password: string }>;
  onSubmit: (values: Client & { password: string }) => void;
  type: "create" | "update";
  loading: boolean;
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={type === "update"}
            label="Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Client ID"
            name="clientId"
            value={formik.values.clientId}
            onChange={formik.handleChange}
            error={formik.touched.clientId && Boolean(formik.errors.clientId)}
            helperText={formik.touched.clientId && formik.errors.clientId}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Client Secret"
            name="clientSecret"
            value={formik.values.clientSecret}
            onChange={formik.handleChange}
            error={
              formik.touched.clientSecret && Boolean(formik.errors.clientSecret)
            }
            helperText={
              formik.touched.clientSecret && formik.errors.clientSecret
            }
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Redirect Uris (in lines)"
            name="redirectUris"
            value={formik?.values?.redirectUris?.join("\n")}
            onChange={(e) => {
              formik.setFieldValue(
                "redirectUris",
                e?.target?.value?.split("\n") || []
              );
            }}
            error={
              formik.touched.redirectUris && Boolean(formik.errors.redirectUris)
            }
            helperText={
              formik.touched.redirectUris && formik.errors.redirectUris
            }
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Scopes (separate by space)"
            name="scopes"
            value={formik?.values?.scopes?.join(" ")}
            onChange={(e) => {
              formik.setFieldValue(
                "scopes",
                e?.target?.value?.split(" ") || []
              );
            }}
            error={formik.touched.scopes && Boolean(formik.errors.scopes)}
            helperText={formik.touched.scopes && formik.errors.scopes}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="responseTypes">Response Types</InputLabel>
            <Select
              labelId="responseTypes"
              multiple
              value={formik.values.responseTypes}
              name="responseTypes"
              onChange={formik.handleChange}
              input={<OutlinedInput label="Response Types"></OutlinedInput>}
              renderValue={(selected) => {
                return selected.join(", ");
              }}
            >
              {_.map(ResponseType, (v, k) => {
                return (
                  <MenuItem key={v} value={v}>
                    <Checkbox
                      checked={formik.values.responseTypes.indexOf(v) > -1}
                    />
                    <ListItemText primary={v} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="grantTypes">Grant Types</InputLabel>
            <Select
              labelId=""
              multiple
              value={formik.values.grantTypes}
              name="grantTypes"
              onChange={formik.handleChange}
              input={<OutlinedInput label="Grant Types"></OutlinedInput>}
              renderValue={(selected) => {
                return selected.join(", ");
              }}
            >
              {_.map(GrantType, (v, k) => {
                return (
                  <MenuItem key={v} value={v}>
                    <Checkbox
                      checked={formik.values.grantTypes.indexOf(v) > -1}
                    />
                    <ListItemText primary={v} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="endpointAuthMethod">
              Token Endpoint Auth Method
            </InputLabel>
            <Select
              labelId=""
              value={formik.values.tokenEndpointAuthMethod}
              name="tokenEndpointAuthMethod"
              onChange={formik.handleChange}
              input={
                <OutlinedInput label="Token Endpoint Auth Method"></OutlinedInput>
              }
              renderValue={(s) => s}
            >
              {_.map(ClientAuthMethod, (v, k) => {
                return (
                  <MenuItem key={v} value={v}>
                    <Checkbox
                      checked={formik.values.tokenEndpointAuthMethod === v}
                    />
                    <ListItemText primary={v} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton loading={loading} variant={"contained"} type="submit">
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
