import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useAuth } from "../../common/hooks/useAuth";
import { useHashParam } from "../../common/hooks/useHashParams";
import { AUTH_STATE } from "./state";

export const CallbackPage = () => {
  const [idToken, setIdToken] = useHashParam("id_token", undefined);
  const [token, setToketn] = useHashParam("access_token", undefined);
  const [expire, setExpire] = useHashParam("expires_in", undefined);
  const { isLogin, setAuth } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (expire && token) {
      setAuth({
        accessToken: token,
        expire: new Date().getTime() / 1000 + Number(expire),
      });
      navigate("/");
    }
  }, [token, expire]);
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
