import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { AUTH_STATE } from "../../pages/auth/state";

export const useAuth = () => {
  const [authState, setAuthState] = useRecoilState(AUTH_STATE);
  const navigate = useNavigate()
  const isLogin = useMemo(() => {
    return (
      authState &&
      authState.accessToken &&
      authState.expire > new Date().getTime() / 1000
    );
  }, [authState]);
  const logout = useCallback(()=>{
    setAuthState({accessToken: null, expire: 0})
    navigate("/login")
  },[])

  return {
    isLogin,
    accessToken: authState?.accessToken,
    expire: authState?.expire,
    setAuth: setAuthState,
    logout
  };
};
