import {
  Toolbar,
  Typography,
  Container,
  Tabs,
  Tab,
  Button,
  makeStyles,
  AppBar,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Link, matchPath, useLocation } from "react-router-dom";
import { useAuth } from "./common/hooks/useAuth";

const menu = [
  {
    label: "Home",
    value: "",
  },
];

export const Navigation = ({ showMenu }: { showMenu?: boolean }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useAuth();
  // const currentTab = routeMatch?.path;
  const location = useLocation();
  const isProd = process.env.REACT_APP_ENV === "production";

  return (
    <AppBar
      position="relative"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar
        sx={
          isProd
            ? { background: "#000", color: "#fff" }
            : { background: "#dcdcdc" }
        }
      >
        <Typography component="h6" variant="h6" sx={{ flexGrow: 0.1 }}>
          FWEN ID
        </Typography>
        <Tabs
          textColor={isProd ? "secondary" : "primary"}
          indicatorColor={isProd ? "secondary" : "primary"}
          sx={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            height: "100%",
            "& .MuiTabs-scroller": {
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              height: "100%",
            },
          }}
          value={``}
        >
          {showMenu
            ? menu.map(({ label, value }) => (
                <Tab
                  label={label}
                  value={value}
                  to={value}
                  key={value}
                  component={Link}
                />
              ))
            : null}
        </Tabs>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            logout();
          }}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};
