import { useLazyQuery, useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import { GET_ALL_CLIENTS } from "./graphql/query";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  GetAllClientsQuery,
  GetAllClientsQueryVariables,
} from "../../generated";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const ClientIndexPage = () => {
  const navigate = useNavigate();

  const { data, loading } = useQuery<
    GetAllClientsQuery,
    GetAllClientsQueryVariables
  >(GET_ALL_CLIENTS);

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader
        title="Client"
        action={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("/clients/new");
            }}
          >
            New
          </Button>
        }
      ></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <DataGridPro
          loading={loading}
          rows={data?.findAllClients || []}
          rowCount={data?.findAllClients?.length || 0}
          columns={[
            { field: "id", headerName: "ID", flex: 0.2 },
            { field: "name", headerName: "Name", flex: 1 },
            { field: "clientId", headerName: "clientId", flex: 1 },
            { field: "updatedAt", headerName: "UpdatedAt", flex: 0.3 },
            {
              field: "edit",
              headerName: "Edit",
              flex: 0.3,
              renderCell: (row) => {
                return (
                  <Button
                    onClick={() => {
                      navigate(`/clients/${row.id}/edit`);
                    }}
                  >
                    Edit
                  </Button>
                );
              },
            },
          ]}
        ></DataGridPro>
      </CardContent>
    </Card>
  );
};
