import { gql } from "@apollo/client";
import { UserFields } from "./fragment";

export const GET_ALL_USERS = gql`
  ${UserFields}
  query GetAllUsers($offset: Int!, $limit: Int!, $where: UserFilterArgs) {
    findAllUsers(offset: $offset, limit: $limit, where: $where) {
      nodes {
        ...UserFields
      }
      totalCount
    }
  }
`;

export const GET_USER = gql`
  ${UserFields}
  query GetUser($id: String!) {
    findUser(id: $id) {
      ...UserFields
    }
  }
`;

export const GET_AUDITS = gql`
  query GetAllAudits($where: AuditFilterArgs, $offset: Int!, $limit: Int!) {
    findAllAudits(offset: $offset, limit: $limit, where: $where) {
      nodes {
        id
        ip
        country
        action
        data
        createdAt
      }
      totalCount
    }
  }
`;
