import React, { useEffect } from "react";
import "./App.css";
import { RecoilRoot, useRecoilSnapshot, useRecoilValue } from "recoil";
import { AUTH_STATE } from "./pages/auth/state";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { client } from "./graphql";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { SnackbarProvider } from "notistack";
import { UserIndexPage } from "./pages/user/UserIndexPage";
import { GeneralLayout } from "./layouts/GeneralLayout";
import { PublicLayout } from "./layouts/PublicLayout";
import { LoginPage } from "./pages/auth/LoginPage";
import { CallbackPage } from "./pages/auth/CallbackPage";
import { UserCreatePage } from "./pages/user/UserCreatePage";
import { UserEditPage } from "./pages/user/UserEditPage";
import { ClientIndexPage } from "./pages/client/ClientIndexPage";
import { ClientCreatePage } from "./pages/client/ClientCreatePage";
import { ClientEditPage } from "./pages/client/ClientEditPage";
import { UserAuditPage } from "./pages/user/UserAuditPage";

function DebugObserver(): JSX.Element {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug("The following atoms were modified:");
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}

const RequireAuth = ({ children }: { children: any }) => {
  const authState = useRecoilValue(AUTH_STATE);
  let location = useLocation();
  if (!authState.accessToken) {
    return <Navigate to={"/login"} state={{ from: location }} />;
  }
  return children;
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={client}>
        <RecoilRoot>
          <DebugObserver />
          <ThemeProvider theme={theme}>
            <CssBaseline></CssBaseline>
            <SnackbarProvider>
              <Router>
                <Routes>
                  <Route element={<PublicLayout></PublicLayout>}>
                    <Route
                      path="/login"
                      element={<LoginPage></LoginPage>}
                    ></Route>
                    <Route
                      path="/callback"
                      element={<CallbackPage></CallbackPage>}
                    ></Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth>
                        <GeneralLayout></GeneralLayout>
                      </RequireAuth>
                    }
                  >
                    <Route
                      path="/users"
                      element={<UserIndexPage></UserIndexPage>}
                    ></Route>
                    <Route
                      path="/users/new"
                      element={<UserCreatePage></UserCreatePage>}
                    ></Route>
                    <Route
                      path="/users/:id/edit"
                      element={<UserEditPage></UserEditPage>}
                    ></Route>
                    <Route
                      path="/users/:id/audits"
                      element={<UserAuditPage></UserAuditPage>}
                    ></Route>

                    <Route
                      path="/clients"
                      element={<ClientIndexPage></ClientIndexPage>}
                    ></Route>
                    <Route
                      path="/clients/new"
                      element={<ClientCreatePage></ClientCreatePage>}
                    ></Route>
                    <Route
                      path="/clients/:id/edit"
                      element={<ClientEditPage></ClientEditPage>}
                    ></Route>
                  </Route>

                  <Route path="/" element={<Navigate replace to="users" />} />
                </Routes>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </RecoilRoot>
      </ApolloProvider>
    </LocalizationProvider>
  );
}

export default App;
