import { Button, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { AUTH_STATE } from "./state";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAuth } from "../../common/hooks/useAuth";

export const LoginPage = () => {
  const { isLogin } = useAuth();
  const location = useLocation();

  //@ts-ignore
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (isLogin) {
      <Navigate to={"/"}></Navigate>;
    }
  }, [isLogin]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display="flex"
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            href={`${
              process.env.REACT_APP_FWEN_ID_URL
            }/oidc/auth?client_id=fwen_admin&client_secret=fwenadminsecret&response_type=id_token token&redirect_uri=${
              window.location.origin
            }/callback&nonce=${Math.ceil(
              Math.random() * 10000000
            )}}&scope=openid offline_access email profile`}
          >
            Login with FWEN ID
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
