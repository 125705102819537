import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { User } from "../../../generated";

export const UserForm = ({
  initialValues,
  onSubmit,
  loading,
  type,
}: {
  initialValues: Partial<User & { password: string }>;
  onSubmit: (values: User & { password: string }) => void;
  type: "create" | "update";
  loading: boolean;
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            disabled={type === "update"}
            label="Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Username"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Password"
            name="password"
            type={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          ></TextField>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="roles">Roles</InputLabel>
            <Select
              labelId="roles"
              multiple
              value={formik.values.roles}
              name="roles"
              onChange={formik.handleChange}
              input={<OutlinedInput label="Roles"></OutlinedInput>}
              renderValue={(selected) => {
                return selected.join(", ");
              }}
            >
              <MenuItem key="admin" value="admin">
                <Checkbox checked={formik.values.roles.indexOf("admin") > -1} />
                <ListItemText primary={"admin"} />
              </MenuItem>
              <MenuItem key="user" value="user">
                <Checkbox checked={formik.values.roles.indexOf("user") > -1} />
                <ListItemText primary={"user"} />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            value={formik.values.enabled}
            label="Enabled"
            onChange={formik.handleChange}
            name="enabled"
            control={<Checkbox checked={formik.values.enabled} />}
          ></FormControlLabel>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton loading={loading} variant={"contained"} type="submit">
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
